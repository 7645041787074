import React, { useState, useRef } from 'react'
import Slider from "react-slick"
import { useStaticQuery, graphql } from 'gatsby'
import './slick.scss'
import './slick-theme.scss'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import Typography from '@material-ui/core/Typography'
import PreviewCompatibleImage from '../previewCompatibleImage'
import clsx from 'clsx'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'; 

const useStyles = makeStyles((theme, props) => ({
  container: {
    backgroundColor: `rgb(208,232,245)`,
    maxHeight: `100vh`,
    [theme.breakpoints.down('md')]: {
      paddingTop: '3rem',
      paddingBottom: '3rem'
    }
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  title: {
    fontWeight: `900`,
  },
  description: {
    fontSize: `24px`,
    [theme.breakpoints.down('md')]: {
      fontSize: `16px`
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: `12px`
    }
  },
  bodyWrapper: {
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `center`,
    maxHeight: `100vh`
  },
  ifrmWrapper: {
    marginLeft: `-24px`,
    paddingTop: `2rem`,
    backgroundColor: `rgb(255,255,255)`,
    width: `100vw`
  },
  quoteWrapper: {
    paddingTop: `0rem`,
  },
  quotes: {
    fontSize: `24px`,
    fontWeight: `-600`,
    paddingTop: `1rem`,
    [theme.breakpoints.down('md')]: {
      fontSize: `18px`
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: `14px`
    }
  },
  quotesAuthor: {
    fontStyle: `initial`,
  },
  classContentContainer: {
    paddingBottom: `2.5rem`
  }
}));

const SliderSlick = () => {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const slider1 = useRef();
  const slider2 = useRef();

  const classes = useStyles()

  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          extension: {
            regex: "/(jpg)|(jpeg)|(png)/"}, 
            relativeDirectory: {
              eq: "schoolTabs"
            }
          },
        sort: {
          fields: name,
          order: ASC
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const { edges: images } = data.allFile

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
   // speed: 4000,
    autoplaySpeed: 5000,
   // cssEase: "linear",
    pauseOnHover: true,
    adaptiveHeight: true,
    nextArrow: <ArrowForwardIosIcon fontSize='large'/>,
    prevArrow:  <ArrowBackIosIcon fontSize='large'/>
  };

  return (
    <>
      <Slider {...sliderSettings}>
        {quotes.map((quote, i) => (
          <Container maxWidth={false} className={classes.container} key={i}>
            <Grid container spacing={3} className={classes.bodyContainer}>
              <Grid item xs={12} sm={12} md={6} className={classes.bodyWrapper} >
                <Container maxWidth='sm' className={classes.quoteWrapper}>
                  <Typography variant='h2'className={classes.title} gutterBottom>
                    what people are saying
                  </Typography>
                  <Typography className={classes.quotes} gutterBottom>
                    {quote.quote} 
                  </Typography>
                  <Typography className={clsx(classes.quotes, classes.quotesAuthor)}>
                    {quote.quoteParent}
                  </Typography>
                </Container>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                  <div className={classes.tabImgWrapper}>
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: images[i].node,
                        alt: `featured image thumbnail for post ${images[i].node.childImageSharp.fluid.src}`,
                      }}
                    />
                  </div>
              </Grid>
            </Grid>
          </Container> 
        ))}
      </Slider>
    </>
  );
};

export default SliderSlick;

const quotes = [
  {
    quote: `"My daughter has been with Molly and her wonderful staff for about 2 years and I have seen her confidence and voice improve in leaps and bounds. My daughter looks forward to her singing classes every week and loves being a part of such a great group of talented kids. The LUX teachers give their students so much care, attention and support... and we are truly grateful for all they do!!"`,
    quoteParent: `LUX Parent, Nay`,
  },
  {
    quote: `“Sophie grew tremendously as an actor through her lessons with Tia. Tia is engaging and nurturing— she fosters a unique sense of discipline so essential for an actor-in-training. Sophie learned techniques that rivaled any programs in NYC. As a result, she grew more confident, more fluid in her verbal and emotive expression that did not simply refine her acting technique but also really developed her ability to publicly speak and interview— a huge asset as she was applying to colleges.”`,
    quoteParent: `LUX Parent, Yasmin`,
  },
  {
    quote: `“The songwriting course at LUX performing arts has been a great platform and outlet for our daughter Fia. Not only is it a place where she can weave her words and feelings into beautiful melodies - but find her true inner voice. From one performance to another, and with the guidance and inspiration of her talented teacher, we have watched her confidence and musical abilities blossom both on stage, and beyond the classroom!”`,
    quoteParent: `LUX Parent, Cory`,
  },
  {
    quote: `“Our daughter has grown so much as a singer — in her vocal range, her comfort level performing, as well as her willingness to try different music styles and genres. She has had opportunities to sing in NYC with a full band, record original music videos, sing at nursing homes... I can’t believe how far she’s come since she first started private lessons with LUX!”`,
    quoteParent: `LUX Parent, Toby`,
  }
]