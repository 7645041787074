import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import ClassesTabs from '../components/classesTabs'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { openPopupWidget } from 'react-calendly';

const useStyles = makeStyles((theme) => ({
  sandwich: {
    height: `0px`,
    transform: `translateY(-35px)`,
    paddingLeft: `10%`,
    zIndex: 99
  },
  wrapper: {
    backgroundColor: `rgb(240,229,215)`,
    flex: `1 1 auto`,
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `center`,
    zIndex: `999`,
  },
  textContainer: {
    transition: `all 0.3s ease`,
    paddingBottom: `3rem`
  },
  text: {
    color: `rgb(254,255,255)`
  },
  minis: {
    paddingTop: `30vh`,
    [theme.breakpoints.down('md')]: {
      paddingTop: `0vh`
    }
  },
  bigText: {
    color: `rgb(13,47,73)`
  },
  link: {
    fontWeight: `bold`,
    color: theme.palette.primary.main
  },
  polkas: {
    background: `linear-gradient(333deg, rgba(0,212,255,1) 0%, rgba(145,51,182,1) 71%)`,
    padding: `2rem`,
  },
  heroImgContainer: {
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`
  },
  heroTextWrapper: {
    alignSelf: `center`,
    color: `#fafafa`,
    padding:`2rem`,
    [theme.breakpoints.down('md')]: {
        padding: `0.5rem`,
        display: 'none',
    },
  },
  heroBtn: {
    marginBottom: `60px`
  },
  consultBtn: {
    background: '#fe66cb',
    border: 0,
    borderRadius: 0,
    boxShadow: '8px 6px #fe3324',
    color: 'white',
    padding: '0 30px',
    fontWeight: '900',
    fontStyle: 'italic',
    textTransform: 'none',
    fontSize: `24px`,
    zIndex: `1000`,
    maxWidth: `350px`,
  },
  btnsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    alignItems: 'center',
  },
  anchor: {
    textAlign: 'center',
    color: '#fff',
    textDecoration: 'none',
    backgroundImage: 'linear-gradient(90deg, rgb(249,72,192), rgb(254,194,40))',
    backgroundSize: '0% 3px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left bottom',
    fontSize: '1.2rem',
    transition: 'background-size 300ms ease',
    '&:hover': {
      backgroundSize: '100% 3px',
    },
  },
}));
const School = ({ location }) => {
  const classes = useStyles()

  const handleConsultationClick = () => {
    openPopupWidget({
      url: 'https://calendly.com/luxperformingarts/30min?embed_domain=luxperformingarts.com&'
    })
  }

  return (
    <Layout>
      <SEO title="Perform at LUX" description="LUX performing arts provides top training in BROADWAY, ACTING & FILM, and SONGWRITING right here in Jersey City. Some students come to LUX for more confidence and some come to train for professional auditions and performances in NYC." />
      <Box className={classes.polkas}>
        <Box>
          <Grid container maxWidth='xl' className={classes.heroTopContainer}>
            <Grid item xs={12} md={6} className={classes.heroTextWrapper} spacing={4}>
              <Grid item>
                <Typography variant='h6' align='center' gutterBottom>
                  LUX Performing Arts provides top training in BROADWAY, ACTING & FILM, and SONGWRITING right here in Jersey City. Some students come to LUX for more confidence while others come to train for professional theatre, film, and songwriting opportunities. Get creative with LUX!
                </Typography>
              </Grid> 
            </Grid>
            <Grid item xs={12} md={6} className={classes.heroImgContainer}>
              <Grid item className={classes.btnsWrapper}>
                <Button variant="contained" color="secondary" className={classes.consultBtn} onClick={handleConsultationClick}>
                  SIGN UP FOR A FREE CONSULTATION
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box component="section" className={classes.wrapper}>
        <ClassesTabs openTab={location.hash ? location.hash : `BROADWAY`} />
      </Box>
    </Layout> 
  )
}

export default School
