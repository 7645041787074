import React from 'react'
import { navigate } from "gatsby"
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import ClassTabContent from './classTabContent'
import { useStaticQuery, graphql } from 'gatsby'
import clsx from 'clsx'
import ColorfulDvdr from './colorfulDvdr'
import Slider from './slider/slickSlider'
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: `#f5f5f5`,
  },
  indicator: {
    height: `40px`,
    background: `transparent`,
    color: `#f5f5f5`,
    display: `flex`,
    justifyContent: `center`,
    '&::after': {
      content: '" "',
      display: 'block',
      width:  60,
      height: 60,
      borderRadius: `50%`,
      backgroundColor: `#f5f5f5`,
    }
  },
  indicator0: {
    '&::after': {
      backgroundColor: `rgb(250,25,29)`,
    }
  },
  indicator1: {
    '&::after': {
      backgroundColor: `rgb(249,72,192)`,
    }
  },
  indicator2: {
    '&::after': {
      backgroundColor: `rgb(254,194,40)`,
    }
  },
  indicator3: {
    '&::after': {
      backgroundColor: `rgb(83,204,162)`,
    }
  },
  rootTabs: {
    overflow: `visible`
  },
  tabsScroller: {
    overflow: `visible !important`
  },
  tab: {
    zIndex: 500,
    color: `rgb(254,255,255) !important`,
    fontSize: `2rem`,
    [theme.breakpoints.down('md')]: {
      fontSize: `1rem`
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: `0.7rem`
    },
  },
  tab0: {
    backgroundColor: `rgb(250,25,29)`,
  },
  tab1: {
    backgroundColor: `rgb(249,72,192)`,
  },
  tab2: {
    backgroundColor: `rgb(254,194,40)`,
  },
  tab3: {
    backgroundColor: `rgb(83,204,162)`,
  },
  panelRoot: {
    padding: `0px`
  }
}));

const openTabIndex = (openTab) => {
  if (openTab === `#BROADWAY`) {
    return 0
  } else if (openTab === `#ACTING`) {
    return 1
  } else if (openTab === `#SONGWRITING`) {
    return 2
  } else if (openTab === `#PRIVATES`) {
    return 3
  } else {
    return 0
  }
}

const openTabRouteHash = (value) => {
  if (value === 0) {
    return `#BROADWAY`
  } else if (value === 1) {
    return `#ACTING`
  } else if (value === 2) {
    return `#SONGWRITING`
  } else if (value === 3) {
    return `#PRIVATES`
  } else {
    return `#BROADWAY`
  }
}

export default function ClassesTabs({ openTab }) {
  const classes = useStyles()
  const theme = useTheme()
  const [value, setValue] = React.useState(openTabIndex(openTab))

  const handleChange = (e, newValue) => {
    setValue(newValue)
    navigate(`/classesAtLux${openTabRouteHash(newValue)}`)
  };

  const handleChangeIndex = (index) => {
    setValue(index)
  };

  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          extension: {
            regex: "/(jpg)|(jpeg)|(png)/"}, 
            relativeDirectory: {
              eq: "schoolTabs"
            }
          },
        sort: {
          fields: name,
          order: ASC
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const indicatorClass = () => {
    if (value === 0) {
      return classes.indicator0
    } else if (value === 1) {
      return classes.indicator1
    } else if (value === 2) {
      return classes.indicator2
    } else if (value === 3) {
      return classes.indicator3
    }
  }

  return (
    <div id='classes' className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="Classes tabs"
          classes={{
            root: classes.rootTabs,
            indicator: clsx(classes.indicator, indicatorClass()),
            scroller: classes.tabsScroller
          }}
          centered
        >
          <Tab label="BROADWAY" className={clsx({
            [classes.tab] : true,
            [classes.tab0] : true})} {...a11yProps(0)} />
          <Tab label="ACTING & FILM" className={clsx({
            [classes.tab] : true,
            [classes.tab1] : true})} {...a11yProps(1)} />
          <Tab label="SONGWRITING" className={clsx({
            [classes.tab] : true,
            [classes.tab2] : true})} {...a11yProps(2)} />
          <Tab label="PRIVATE CLASSES" className={clsx({
            [classes.tab] : true,
            [classes.tab3] : true})} {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
        style={{backgroundColor: `rgb(255,255,255)`, paddingTop: `1rem`, paddingBottom: `1rem`}}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <ClassTabContent contentType={`BROADWAY`} img={data.allFile.edges[0].node} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <ClassTabContent contentType={`ACTING`} img={data.allFile.edges[1].node} />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <ClassTabContent contentType={`SONGWRITING`} img={data.allFile.edges[2].node} />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <ClassTabContent contentType={`PRIVATES`} img={data.allFile.edges[3].node} />
        </TabPanel>
      </SwipeableViews>
      <ColorfulDvdr />
      <Slider />
    </div>
  );
}