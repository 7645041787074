import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles((theme, props) => ({
  container: {
    backgroundColor: `rgb(208,232,245)`
  },
  bodyContainer: {
    '&::after': {
      marginBottom: `-20px`,
      content: '" "',
      display: 'block',
      width:  60,
      height: 60,
      borderRadius: `50%`,
      backgroundColor: `rgb(208, 232, 245)`,
      position: `relative`,
      left: `45%`
    }
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  title: {
    fontSize: `32px`,
    fontWeight: `900`,
    [theme.breakpoints.down('md')]: {
      fontSize: `24px`
    }
  },
  description: {
    fontSize: `24px`,
    [theme.breakpoints.down('md')]: {
      fontSize: `16px`
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: `12px`
    }
  },
  bodyWrapper: {
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `center`
  },
  tabImgWrapper: {
    padding: `5px`,
  },
  ifrmWrapper: {
    marginLeft: `-24px`,
    paddingTop: `2rem`,
    backgroundColor: `rgb(255,255,255)`,
    width: `100vw`
  },
  quoteWrapper: {
    paddingTop: `2rem`,
  },
  quotes: {
    fontSize: `16px`,
    fontStyle: `italic`,
    [theme.breakpoints.down('md')]: {
      fontSize: `12px`
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: `10px`
    }
  },
  quotesAuthor: {
    fontStyle: `initial`,
    textAlign:`right`
  },
  classContentContainer: {
    paddingBottom: `2.5rem`,
    [theme.breakpoints.down('md')]: {
      display: 'none',
  },
  }
}));


const ClassTabContent = ({ contentType, img }) => {
  const classes = useStyles()
  const { title, contents, src, quote, quoteParent } = tabContents[contentType]
  const matchesSmall = useMediaQuery('(max-width:816px)');
  const matchesBig = useMediaQuery('(min-width:1327px)');

  const getIfrmHeight = () => {
    if (contentType === 'BROADWAY') {
      return matchesSmall ? `3850px` : matchesBig ? `1700px` : `2100px`
    } else if (contentType === 'ACTING')  {
      return matchesSmall ? `1300px` : matchesBig ? `500px` : `900px`
    } else if (contentType === 'SONGWRITING') {
      return matchesSmall ? `1600px` : matchesBig ? `850px` : `850px`
    } else if (contentType === 'PRIVATES')  {
      return matchesSmall ? `3050px` : matchesBig ? `1200px` : `1600px`
    }
  }

  return (
    <>
      <div className={classes.ifrmWrapper}>
        <Container maxWidth='xl'>
          <Container maxWidth='lg' className={classes.classContentContainer}>
            <Typography variant='h6'>
              {contents}
            </Typography>
          </Container>
          <iframe title={title} id={src.substring(src.lastIndexOf('/') + 1)} src={src} height={getIfrmHeight()} width='100%' frameBorder='0'></iframe>
        </Container>
      </div>
    </>
  );
}

export default ClassTabContent

const tabContents = {
  BROADWAY: {
    title: `BROADWAY STUDIO`,
    contents: `Musical Theater is an ever evolving American art form coming all the way from operetta (Show Boat) to spoken word (Hamilton). LUX is dedicated to staying current in the craft while learning from it’s history. The BROADWAY STUDIO at LUX provides training in singing and song interpretation (acting while singing). Beginner students should check out our group musical theatre classes while advanced students can audition for Junior, Stars, and Broadway Company! We also offer group voice classes.`,
    quote: `"My daughter has been with Molly and her wonderful staff for about 2 years and I have seen her confidence and voice improve in leaps and bounds. My daughter looks forward to her singing classes every week and loves being a part of such a great group of talented kids. The LUX teachers give their students so much care, attention and support... and we are truly grateful for all they do!!"`,
    quoteParent: `LUX Parent, Nay`,
    src: `https://luxperformingarts.getomnify.com/widgets/SPBDCBOGNKL6IBOBHFCC`
  },
  ACTING: {
    title: `ACTING & FILM`,
    contents: `The ACTING & FILM STUDIO provides students with the fundamentals to dive into a well rounded career on stage and on film. Our goal is to provide opportunities across the board in acting, from writing to directing to producing new work.  New students can play their favorite characters on screen in our TV/Film Scenes class. Older students can explore long-form improv and character development in Advanced Improv. Stay tuned for opportunities to develop short films for the film festival circuit! Check out our fall collaborative class Pop Star Music Video.`,
    quote: `“Sophie grew tremendously as an actor through her lessons with Tia. Tia is engaging and nurturing— she fosters a unique sense of discipline so essential for an actor-in-training. Sophie learned techniques that rivaled any programs in NYC. As a result, she grew more confident, more fluid in her verbal and emotive expression that did not simply refine her acting technique but also really developed her ability to publicly speak and interview— a huge asset as she was applying to colleges.”`,
    quoteParent: `LUX Parent, Yasmin`,
    src: `https://luxperformingarts.getomnify.com/widgets/FNARKZGSD8D2OO6D1CUJO2`
  },
  SONGWRITING: {
    title: `SONGWRITING`,
    contents: `If you’ve had any interest in creating your own music, our SONGWRITING STUDIO is for you. For beginners, we offer Songwriting 101. We highly recommend that students are registered for private voice, piano, or guitar to get the most out of this course. Group Guitar is for beginner to intermediate musicians who want to get confident in their guitar skills, either for original work or famous pop songs. Coming soon, our SONGWRITER’S CIRCLE will be a forum for advanced students to try out new material, progress in their writing technique, learn at home recording tools, and explore other industry related opportunities. Check out our fall collaborative class Pop Star Music Video.`,
    quote: `“The songwriting course at LUX performing arts has been a great platform and outlet for our daughter Fia. Not only is it a place where she can weave her words and feelings into beautiful melodies - but find her true inner voice. From one performance to another, and with the guidance and inspiration of her talented teacher, we have watched her confidence and musical abilities blossom both on stage, and beyond the classroom!”`,
    quoteParent: `LUX Parent, Cory`,
    src: `https://luxperformingarts.getomnify.com/widgets/95TQAO9FAJW10C1JKIIL`
  },
  PRIVATES: {
    title: `PRIVATE LESSONS`,
    contents: `Private lessons are for serious singers, actors, and musicians ready to take their performing to the next level. These one-on-one coachings are appropriate for focused individuals who have a deep passion for singing, acting, piano, guitar, or songwriting. We build technique while also preparing kids for any upcoming auditions, performances, and opportunities. Level up! Sign up below for a PRIVATE LESSONS membership for the 2020-2021 academic calendar.`,
    quote: `“Our daughter has grown so much as a singer — in her vocal range, her comfort level performing, as well as her willingness to try different music styles and genres. She has had opportunities to sing in NYC with a full band, record original music videos, sing at nursing homes... I can’t believe how far she’s come since she first started private lessons with LUX!”`,
    quoteParent: `LUX Parent, Toby`,
    src: `https://luxperformingarts.getomnify.com/widgets/6UA1O30F3UK4P9WTRMMDO6`
  }
}
